import Typography from 'typography'

const typography = new Typography({
	bodyFontFamily: [
		'-apple-system',
		//'BlinkMacSystemFont', Disabled until Chrome fixes the font weight issue: https://bugs.chromium.org/p/chromium/issues/detail?id=1057654
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		'sans-serif',
	],
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
	typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
